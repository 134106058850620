import React, { useEffect } from 'react';
import { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';

function SignIn() {

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSignIn = async () => {
        if (id === '' || password === '') {
            alert('Please fill in all fields');
        } else {
            try {
                await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
                    body: JSON.stringify({
                        id: `${id}`,
                        password: `${password}`,
                    }),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(response => {
                    if (!response.ok) {
                      console.log("response: ", response.status);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('data: ', data);
                    console.log('session: ', data.session, 'role: ', data.role, 'organization: ', data.organization);
                    localStorage.setItem('session', data.session);
                    localStorage.setItem('role', data.role);
                    localStorage.setItem('organization', data.organization);
                    if (data.message == 'Success') {
                        navigate('/console');
                    }
                });


            }
             catch (error) {
                console.log(error);
            }
        }

    }

  return (
    <div className="dark:bg-gray-900 flex-grow py-12">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
              <img className="w-20 mr-4" src="smv-logo.svg" alt="logo" />
              Smartvote
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                      Sign in to your account
                  </h1>
                  <div className="space-y-4 md:space-y-6">
                      <div>
                          <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your ID</label>
                          <input type="id" name="id" id="id" onChange={(e) => setId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your company ID" required="" />
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input type="password" name="password" id="password" placeholder="••••••••" onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-red-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                      </div>
                      <div className="flex items-center justify-between">
                          <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-red-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-red-600 dark:ring-offset-gray-800" required="" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                              </div>
                          </div>
                      </div>
                      <button onClick={handleSignIn} className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Sign in</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
  
}

export default SignIn;