import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from "../components/navbar";

function OrganizationDetail() {
    const { id } = useParams(); // Get the organization ID from the URL parameters
    const [homeworkSections, setHomeworkSections] = useState([]);

    // Fetch the organization details based on the ID
    useEffect(() => {
        const fetchOrganization = async () => {
        try {
            const response = await fetch('https://api.smv.hk/whiteboard/get_names_from_organization', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ organization: id }), // Send the ID as 'organization' in the request body
                });

            if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setHomeworkSections(data.name); // Set the fetched organization data
        } catch (error) {
            console.error('Error fetching organization:', error);
        }
        };

        fetchOrganization();
    }, [id]);

    return (
        <>
            <Navbar />
            <div className="flex flex-col py-12 w-full items-center min-h-screen">
                <h1 className="text-3xl font-bold mb-8 text-gray-800">Homework Sections</h1>
                {homeworkSections.length > 0 ? (
                    <div className="w-3/4 max-w-2xl bg-white shadow-md rounded-lg p-6">
                        <ul className="pl-6">
                            {homeworkSections.map((sectionName, index) => (
                            <li key={index} className="mb-2 list-disc">
                                <a
                                href={`/homework?name=${sectionName}`} // Adjust the href as per your routing structure
                                className="text-blue-500 hover:text-blue-700 transition-colors"
                                >
                                {sectionName}
                                </a>
                            </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className="flex items-center justify-center py-4">
                        <svg
                            className="animate-spin h-5 w-5 mr-3 text-blue-500"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                            ></path>
                        </svg>
                        <div className="text-lg font-medium text-gray-600">Loading...</div>
                    </div>
                )}
            </div>
        </>
    );
}

export default OrganizationDetail;
