
import { useState } from 'react';

const ProductActivation = () => {

    const [productId, setProductId] = useState('7028903b-7b9d-11ee-a3dd-0242ac140008');
    const [number, setNumber] = useState('');
    const [activation_key, setActivationKey] = useState([]);

    const handleValidationAndFetch = () => {
        let session = window.localStorage.getItem('session');
        // Check if productId and number are valid
        if (productId && number) {
            // Fetch API with productId and number
            fetch( `${process.env.REACT_APP_BACKEND_URL}/activation/create`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                session: `${session}`,
                num: number,
                product_id: productId
                }),
            } ).then(response => {
                return response.json();
            })
            .then((data) => {
                setActivationKey(data.activation_key);
            });
        } else {
            // Handle invalid input
            console.error('Invalid input: Product ID and number are required.');
        }
    };

    return(
        <div className='p-4 sm:ml-64 dark:bg-gray-900 min-h-screen dark:text-white'>
            <div className='flex flex-row flex-none justify-between items-center'>
                <p className='text-xl font-bold text-gray-700 dark:text-white py-6'>Product Activation</p>
            </div>
            <div className='flex flex-col items-center'>
                <div className='m-4 flex w-1/3'>
                    <select className='flex-1 px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                        value={productId}
                        onChange={(e) => setProductId(e.target.value)}>
                        <option value='7028903b-7b9d-11ee-a3dd-0242ac140008'>After School Display Board</option>
                    </select>
                </div>
                <div className='m-4 flex w-1/3'>
                    <input 
                        type="number" 
                        className=" flex-1 px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="no. of activation key"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)} />
                </div>
                <div className='m-4 flex w-1/3'>
                    <button 
                        className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        onClick={handleValidationAndFetch}
                    >
                        Generate
                    </button>
                </div>
                <div className='m-4 '>
                    <p>Activation Key List:</p>
                    <ul>
                        {activation_key.map((key, index) => (
                            <li key={index}>{key}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );    
}

export default ProductActivation;
