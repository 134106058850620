import React from 'react';
import SettingModal from './SettingModal';
import DatePicker from './DatePickerCom';

const groupByCompany = (arr = []) => {
    if (!Array.isArray(arr)) {
        console.warn("groupByCompany function expects an array as an argument.");
        return {};
    }

    return arr.reduce((acc, item) => {
        const companyName = item.organization || 'Unknown';
        acc[companyName] = acc[companyName] || [];
        acc[companyName].push(item);
        return acc;
    }, {});
};

export default function LicenseManager({ isModalOpen, setModalOpen, modalRef, json, handleDropdownChange, handleStartFrom, handleEndOn, datePickerRef, fetchData, updateData }) {
    const [userNameOpen, setUserNameOpen] = React.useState('New User');
    const groupedAccounts = groupByCompany(json);
    const [expandedCompany, setExpandedCompany] = React.useState(null);

    const handleRowClick = (event, user_name) => {
        if (event.target.tagName === 'TD' || event.target.tagName === 'TH') {
            setUserNameOpen(user_name);
            setModalOpen(!isModalOpen)
        }
    };

    if (!json || json.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div className="p-4 sm:ml-64 dark:bg-gray-900 min-h-screen">
            <div className='flex flex-row justify-between items-center'>
                <p className='text-xl font-bold text-gray-700 dark:text-white py-6'>Manage Users</p>
                <button 
                    className='bg-blue-500 text-white px-4 py-2 rounded'
                    onClick={() => {
                        setUserNameOpen('New User');
                        setModalOpen(!isModalOpen);
                    }}
                >
                    Add New Users
                </button>
                <SettingModal 
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    modalRef={modalRef}
                    userNameOpen={userNameOpen}
                    datePickerRef={datePickerRef}
                    fetchData={fetchData}
                    json={json}
                    updateData={updateData}
                />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 dark:text-gray-300 uppercase bg-gray-50 dark:bg-gray-900">
                        <tr>
                            <th scope="col" className="px-6 py-3 w-3/10">
                                client name
                            </th>
                            <th scope="col" className="px-6 py-3 w-3/10">
                                role
                            </th>
                            <th scope="col" className="px-6 py-3 w-2/5">
                                date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.entries(groupedAccounts).map(([company, users]) => (
                                <React.Fragment key={company}>
                                    <tr onClick={() => setExpandedCompany(company === expandedCompany ? null : company)}>
                                        <td colSpan="3" className="px-6 py-4 font-bold text-left cursor-pointer">
                                            {company}
                                        </td>
                                    </tr>
                                    {expandedCompany === company && users.map((data) => (
                                        <tr 
                                            key={data.name}
                                            onClick={e => handleRowClick(e, data.name)} 
                                            className="border-b hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-900 cursor-pointer"
                                        >
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap w-3/10">
                                                {data.name}
                                            </th>
                                            <td className="px-6 py-4 w-3/10">
                                                <select 
                                                    value={data.role} 
                                                    onChange={e => handleDropdownChange(e, data.name)}
                                                    className='p-1 rounded-md dark:bg-gray-950 focus:outline-none'
                                                >
                                                    <option value="1">Root Account</option>
                                                    <option value="2">IT Admin</option>
                                                    <option value="3">IT Device</option>
                                                    {/* ... other options ... */}
                                                </select>
                                            </td>
                                            <td className="px-6 py-4 w-2/5">
                                                {
                                                    (data.role == 1)?
                                                    <></>
                                                    :
                                                    <DatePicker startDate={data.start_date} endDate={data.end_date} user_name={data.name} handleStartFrom={handleStartFrom} handleEndOn={handleEndOn} />
                                                    
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
