import React, { useEffect } from 'react';
import Navbar from "./components/navbar";
import SectionTitle from "./components/sectionTitle";
import { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';


export default function privacy() {
	return (
		<>
			<Navbar />

			<main className="flex min-h-screen flex-col items-center justify-between p-24">

                <section className="mb-8">
                    <h1 className="text-3xl font-bold mb-4">Privacy Policy for SMV</h1>
                    <p className="mb-4">SMV (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the mobile application (the &quot;App&quot;).</p>
                    <p className="mb-4">This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our App and the choices you have associated with that data.</p>
                    <p className="mb-4">We use your data to provide and improve the App. By using the App, you agree to the collection and use of information in accordance with this policy.</p>
                    <p className="mb-4"><strong>Information Collection and Use:</strong> We do not collect any personally identifiable information in our App. However, the App may collect certain non-personally identifiable information automatically, including, but not limited to, the type of mobile device you use, your mobile operating system, and information about the way you use the App.</p>
                    <p className="mb-4"><strong>Use of Data:</strong> The non-personally identifiable information collected is used solely to improve the quality and experience of the App. This information helps us understand how users interact with the App and allows us to make enhancements accordingly.</p>
                    <p className="mb-4"><strong>Data Security:</strong> We are committed to protecting the security of your information. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                    <p className="mb-4"><strong>Changes to This Privacy Policy:</strong> We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p className="mb-4"><strong>Contact Us:</strong> If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at info@smv.hk.</p>
                </section>

			</main>

		</>
	)
}