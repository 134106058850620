import React, { useEffect } from 'react';
import Navbar from "./components/navbar";
import SectionTitle from "./components/sectionTitle";
import { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';

function Home() {

    const navigate = useNavigate();

    return (
        <>
            <Navbar />        
            <div className="dark:bg-gray-900 flex-grow py-12">
                
            <SectionTitle
                pretitle="Welcome"
                title="">
                Welcome to SMV, your one-stop destination for exploring the myriad features of the Student Management System. Dive into the future of education with our innovative platform that seamlessly integrates various aspects of student life, with a special focus on the Homework section.
            </SectionTitle>

            </div>
        </>
    );
  
}

export default Home;