import React, { useEffect } from 'react';
import Navbar from "../components/navbar";
import Edit from "./edit";
import { useState } from 'react'; 
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';

function Section() {

    const navigate = useNavigate();
    const [tableData, setTableData] = useState();
    const [maxCol, setMaxCol] = useState(0);
    const [maxRow, setMaxRow] = useState(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const name = searchParams.get("name");
    const date = searchParams.get("date");

    useEffect( () => {


        const fetchData = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_target_table`;
            const postData = {
                user_name: name,
                date: date
            };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            };

            try {
                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                const dataMap = new Map();
                let maxCol = 0;
                let maxRow = 0;

                for (const entry of data.table) {
                    const col = parseInt(entry.col);
                    const row = parseInt(entry.row);

                    if (col > maxCol) {
                        maxCol = col;
                    }
                    if(row > maxRow){
                        maxRow = row;
                    }
                    
                    const data = entry.data;
                    const deadline = entry.deadline;
                    
                    if (!dataMap.has(row)) {
                        dataMap.set(row, new Map());
                    }
                    // if (!dataMap.has(row+1)) {
                    //     dataMap.set(row+1, new Map());
                    // }
                    
                    dataMap.get(row).set(col, data);
                    dataMap.get(row).set(col+1, deadline);
                }
                maxCol++;
                dataMap.get(1).set(3, "Deadline");
                setMaxCol(maxCol);
                setMaxRow(maxRow);
                setTableData(dataMap);
                
            } catch (error) {
                console.error('There was a problem with the POST request:', error);
            }
            
        };
          
        fetchData();

    }, []);
    
    return (
        <>
            <Navbar />        
            <div className="flex flex-col py-12 w-full items-center justify-center">

            <h1 className="text-3xl font-bold mb-4">Today's Homework</h1>
            <p className="text-lg mb-8">Here is the list of homework assignments due today. Please ensure you complete them on time.</p>
                
                <table>
                    <tbody>
                        {tableData && (() => {
                            const rows = [];
                            for (let row = 1; row <= maxRow; row++) {
                                const cells = [];
                                // Iterate over rows
                                for (let col = 1; col <= maxCol; col++) {
                                    // Push cell data into cells array
                                    cells.push(<td key={col}>{tableData.get(row)?.get(col) || ''}</td>);
                                }
                                // Push row with cells into rows array
                                rows.push(<tr key={row}>{cells}</tr>);
                            }
                            return rows;
                        })()}
                    </tbody>
                </table>
                
            </div>
        </>
    );
  
}

export default Section;