import React, { useEffect, useState } from 'react';

const Whiteboard = () => {

  const [row, setRow] = useState(0);
  const [col, setCol] = useState(0);
  const [table, setTable] = useState();
  const [colWidths, setColWidths] = useState([100, 100, 100]); // Widths of the three columns
  const [headerWidth, setHeaderWidth] = useState(100); // Width of the row header
  const [headers, setHeaders] = useState([
    // { name: '中文', rows: [50, 50] },
    // { name: 'English', rows: [50] },
    // { name: 'Math', rows: [50] },
    // { name: 'LS', rows: [50] },
  ]); // Headers and their rows

  // New state variable for storing cell data
  const [cellData, setCellData] = useState({});

  const [newHeaderName, setNewHeaderName] = useState('');
  const [selectedHeader, setSelectedHeader] = useState('');

  const handleColResize = (index, initialX, initialWidth, e) => {
    const delta = e.clientX - initialX;
    const newWidth = initialWidth + delta;
    setColWidths((prevWidths) => {
      const newColWidths = [...prevWidths];
      newColWidths[index] = newWidth;
      return newColWidths;
    });
  };

  const handleRowResize = (headerIndex, rowIndex, initialY, initialHeight, e) => {
    const delta = e.clientY - initialY;
    const newHeight = initialHeight + delta;
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      newHeaders[headerIndex].rows[rowIndex] = newHeight;
      return newHeaders;
    });
  };

  const handleHeaderResize = (initialX, initialWidth, e) => {
    const delta = e.clientX - initialX;
    const newWidth = initialWidth + delta;
    setHeaderWidth(newWidth);
  };

  const addRow = async (owner, data) => {
    let session = window.localStorage.getItem('session');

    await fetch( `${process.env.REACT_APP_BACKEND_URL}/whiteboard/new_row`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session: `${session}`,
        uuid: `${owner}`,
        height: 5,
        width: 50,
        data: data
      }),
    } );

  };

  const removeRow = (headerIndex) => {
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      if (newHeaders[headerIndex].rows.length > 1) {
        newHeaders[headerIndex].rows.pop();
      }
      return newHeaders;
    });
  };

  const handleCellChange = (rowIndex, colIndex, event) => {
    const updatedTable = [...table];
    updatedTable[rowIndex][colIndex] = event.target.value;
    setTable(updatedTable);
    console.log(updatedTable);
  };

  const addCol = async (owner, data) => {

  } 

  const addHeader = () => {
    if (newHeaderName) {
      setHeaders((prevHeaders) => [...prevHeaders, { name: newHeaderName, rows: [50] }]);
      addCol("7920ebb3-7ece-11ee-98cb-0242ac140008", newHeaderName)
      setNewHeaderName('');
    }
  };

  const removeHeader = () => {
    setHeaders((prevHeaders) => {
      const newHeaders = prevHeaders.filter((header) => header.name !== selectedHeader);
      return newHeaders;
    });
    setSelectedHeader('');
  };

  const moveHeader = (headerIndex, direction) => {
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      const index = headerIndex + direction;
      if (index >= 0 && index < newHeaders.length) {
        [newHeaders[headerIndex], newHeaders[index]] = [newHeaders[index], newHeaders[headerIndex]];
      }
      return newHeaders;
    });
  };

  useEffect(() => {
    console.log('colwidth: ', colWidths);
    console.log('headerwidth: ', headerWidth);
    console.log('headers: ', headers);
  }, [colWidths, headerWidth, headers]);

  useEffect(() => {
    console.log('celldata: ', cellData);
  }, [cellData]);

  useEffect(
    async () => {

      let session = window.localStorage.getItem('session');

      await fetch( `${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_design`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session: `${session}`,
          owner: `7920ebb3-7ece-11ee-98cb-0242ac140008`
        }),
      } )
      .then( (data) => data.json())
      .then( (data) =>{
        var _table = [];
        var max_row = 0, max_col = 0;
        for (var i = 0; i < data.table.length; i++){
          var row_temp = data.table[i].row;
          var col_temp = data.table[i].col;

          if (row_temp == undefined){
            row_temp = 1;
            col_temp++;
          }
          if (col_temp == undefined){
            col_temp = 1;
            row_temp++;
          }

          if (row_temp > max_row){
            max_row = row_temp;
            setRow( max_row );
          }
          if (col_temp > max_col){
            max_col = col_temp;
            setCol( max_col );
          }

          _table.push( {row: row_temp, col: col_temp, data: data.table[i].data} );
        }

        var table_map = [];
        for ( var i = 0; i < max_row; i++ ){
          var arr = [];
          for ( var j = 0; j < max_col; j++ ){
            arr.push( "" );
          }
          table_map.push(arr);
        }
        for (var i = 0; i < _table.length; i++){
          table_map[_table[i].row-1][_table[i].col-1] = _table[i].data;
        }
        setTable(table_map);
        var header = [];
        for (var i = 0; i < table_map.length; i ++){
          header.push( { name: table_map[i][0], rows: [50] } );
        }
        setHeaders( header );       

        return table_map;
      } ).then( table_map => {
        // Fetch the second API after the first one completes
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_target_table`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_name: "Smartvote IT device 1"
          })
        })
        .then(response => response.json())
        .then((data) => {
          const newTableMap = [...table_map];
          for (let i = 0; i < data.table.length; i++){
            newTableMap[data.table[i].row-1][data.table[i].col-1] = data.table[i].data;
          }
          setTable(newTableMap);
        });
      });
      
      return null;

    }, []
  );

  return (
    <div className="flex flex-col justify-start items-start h-screen">
      <table className="border dark:border-gray-900 m-4">
        <thead>
          <tr>
            <th
              style={{ width: headerWidth }}
              className="border p-2 relative dark:border-gray-900"
            >
              
              <div
                className="absolute inset-y-0 right-0 cursor-ew-resize bg-gray-300 dark:bg-gray-700"
                style={{ width: 5 }}
                onMouseDown={(e) => {
                  const initialX = e.clientX;
                  const initialWidth = headerWidth;
                  document.onmousemove = (ev) => handleHeaderResize(initialX, initialWidth, ev);
                  document.onmouseup = () => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                  };
                }}
              ></div>
            </th>
            {[''].map((text, index) => (
              <th
                key={text}
                style={{ width: colWidths[index] }}
                className="border p-2 relative dark:border-gray-900"
              >
                {text}
                <div
                  className="absolute inset-y-0 right-0 cursor-ew-resize bg-gray-300"
                  style={{ width: 5 }}
                  onMouseDown={(e) => {
                    const initialX = e.clientX;
                    const initialWidth = colWidths[index];
                    document.onmousemove = (ev) => handleColResize(index, initialX, initialWidth, ev);
                    document.onmouseup = () => {
                      document.onmousemove = null;
                      document.onmouseup = null;
                    };
                  }}
                ></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {headers.map((header, headerIndex) => (
            <React.Fragment key={header.name}>
              {header.rows.map((rowHeight, rowIndex) => (
                <tr key={rowIndex} style={{ height: rowHeight }} className="relative">
                  {rowIndex === 0 && (
                    <td
                      rowSpan={header.rows.length}
                      style={{
                        height: header.rows.reduce((acc, val) => acc + val, 0),
                        verticalAlign: 'middle',
                      }}
                      className="border p-2 bg-gray-200 dark:bg-gray-800 dark:border-gray-900"
                    >
                      {header.name}
                      <button onClick={() => addRow(headerIndex)} className="ml-2">+</button>
                      <button onClick={() => removeRow(headerIndex)} className="ml-2">-</button>
                      <button onClick={() => moveHeader(headerIndex, -1)} className="ml-2">Up</button>
                      <button onClick={() => moveHeader(headerIndex, 1)} className="ml-2">Down</button>
                    </td>
                  )}
                  {
                  ['Column 1'].map((col, colIndex) => (
                    <td key={col} className="border p-0 dark:border-gray-700">
                      <input
                        className='h-full'
                        type="text"
                        value={table[headerIndex][1]}
                        onChange={(event) => handleCellChange(headerIndex, 1, event)}
                      />
                    </td>
                  ))
                  }
                  <div
                    className="absolute inset-x-0 bottom-0 cursor-ns-resize bg-gray-300 dark:bg-gray-700"
                    style={{
                      height: 5,
                      opacity: rowIndex === header.rows.length - 1 ? 1 : 0
                    }}
                    onMouseDown={(e) => {
                      const initialY = e.clientY;
                      const initialHeight = rowHeight;
                      document.onmousemove = (ev) => handleRowResize(headerIndex, rowIndex, initialY, initialHeight, ev);
                      document.onmouseup = () => {
                        document.onmousemove = null;
                        document.onmouseup = null;
                      };
                    }}
                  ></div>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="flex m-4">
        <input
          type="text"
          placeholder="New Header Name"
          value={newHeaderName}
          onChange={(e) => setNewHeaderName(e.target.value)}
          className="mr-2 dark:bg-gray-800 dark:text-white"
        />
        <button onClick={addHeader} className="mr-2 border rounded-xl">Add Header</button>
        <select
          value={selectedHeader}
          onChange={(e) => setSelectedHeader(e.target.value)}
          className="mr-2 dark:bg-gray-800 dark:text-white"
        >
          <option value="" disabled>Select Header to Remove</option>
          {headers.map((header, index) => (
            <option key={index} value={header.name}>{header.name}</option>
          ))}
        </select>
        <button onClick={removeHeader} className='mr-2 border rounded-xl'>Remove Header</button>
      </div>
    </div>
  );
};

export default Whiteboard;
