import React, { useEffect, useState } from 'react';
import Navbar from "../components/navbar";

function List() {
  const [organizations, setOrganizations] = useState([]);

  // Function to fetch organizations data
  const fetchOrganizations = async () => {
    try {
      const response = await fetch('https://api.smv.hk/whiteboard/get_all_organizations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}), // Add any necessary request payload here
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);
      setOrganizations(data.organizations); // Adjust based on actual response structure
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex flex-col py-12 w-full items-center min-h-screen">
        <h1 className="text-3xl font-bold mb-8 text-gray-800">Organizations</h1>
        {organizations.length > 0 ? (
          <div className="w-3/4 max-w-2xl bg-white shadow-md rounded-lg p-6">
            <ul className="pl-6">
              {organizations.map((name, index) => (
                <li key={index} className="mb-4 list-disc">
                  <a
                    href={`organization/${name}`}
                    className="text-lg text-blue-500 hover:text-blue-700 transition-colors"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
            <div className="flex items-center justify-center py-4">
                <svg class="animate-spin h-5 w-5 mr-3 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                </svg>
                <div className="text-lg font-medium text-gray-600">Loading...</div>
            </div>
        )}
      </div>
    </>
  );
}

export default List;
