import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import Homework from "./homeworkSection";
import Deadline from "./CheckDeadline";
import SignIn from './SignIn';
import Console from './Console';
import Privacy from './privacy';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
  return (
    <div>
      <ReactNotifications />
      <BrowserRouter>
        <div className="flex flex-col min-h-screen bg-gray-200">
          {/* <Navbar/> */}
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/homework/*" element={<Homework/>} />
            <Route path="/deadline" element={<Deadline/>} />
            <Route path="/login" element={<SignIn/>} />
            <Route path="/console" element={<Console/>} />
            <Route path="/legal/privacy-policy" element={<Privacy/>} />
          </Routes>
        </div>
        
      </ BrowserRouter>
    </div>
  );
}

export default App;
