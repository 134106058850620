import React, { useState, useEffect } from 'react';

const Games = () => {
  const [games, setGames] = useState([]);
  const [newGame, setNewGame] = useState('');
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState('');
  const [newMember, setNewMember] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [gameInfo, setGameInfo] = useState(null);
  const [selectedGameId, setSelectedGameId] = useState('f326cea3-3b51-11ef-886d-0242ac140008'); // Added state for selected game ID

  const handleCreateGame = async () => {
    try {
      let session = window.localStorage.getItem('session');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/games/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          session: session,
          name: newGame,
          genre: 'Matching Game'
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create game');
      }

      const createdGame = await response.json();
      setGames([...games, createdGame]);
      setNewGame('');
    } catch (error) {
      console.error(error);
      alert('Failed to create game');
    }
  };

  const handleAddGroup = async () => {
    if (!selectedGameId) {
      alert('Please select a game first');
      return;
    }

    try {
      let session = window.localStorage.getItem('session');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/games/${selectedGameId}/add_group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          session: session,
          group: newGroup
         }),
      });

      if (!response.ok) {
        throw new Error('Failed to add group');
      }

      const updatedGame = await response.json();
      // Update the state with the new group data
      setGroups([...groups, { groupName: newGroup, members: [] }]);
      setNewGroup('');
    } catch (error) {
      console.error(error);
      alert('Failed to add group');
    }
  };

  const handleAddMember = async () => {
    if (selectedGroup === null) {
      alert('Please select a group to add member');
      return;
    }

    try {
      let session = window.localStorage.getItem('session');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/games/${selectedGameId}/add_member`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session: session,
          group: groups[selectedGroup].groupName,
          member: newMember
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add member');
      }

      const updatedGroup = await response.json();
      const updatedGroups = [...groups];
      updatedGroups[selectedGroup] = {
        ...updatedGroups[selectedGroup],
        members: [...updatedGroups[selectedGroup].members, newMember]
      };
      setGroups(updatedGroups);
      setNewMember('');
    } catch (error) {
      console.error(error);
      alert('Failed to add member');
    }
  };

  const handleGetGameInfo = async (gameId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/games/${gameId}/get_info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 400) {
          throw new Error('Bad Request');
        }
        if (response.status === 404) {
          throw new Error('Not Found');
        }
        throw new Error('Failed to fetch game info');
      }

      const data = await response.json();
      setGameInfo(data.data);
      // Update the state with the group and member data
      const fetchedGroups = data.data.map(group => ({
        groupName: group.groupName,
        members: group.members.map(member => member.memberName)
      }));
      setGroups(fetchedGroups);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  return (
    <div className="p-4 sm:ml-64 dark:bg-gray-900 min-h-screen dark:text-white">
      <div className="mb-4">
        <h1 className="text-xl font-bold mb-2">Create a Matching Game</h1>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Game Name
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={newGame}
            onChange={(e) => setNewGame(e.target.value)}
          />
        </div>
        <button
          onClick={handleCreateGame}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create Game
        </button>
      </div>

      <div className="mb-4">
        <h2 className="text-lg font-bold mb-2">Select Game</h2>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={selectedGameId}
          onChange={(e) => setSelectedGameId(e.target.value)}
        >
          <option value="">Select a game</option>
          {games.map((game, index) => (
            <option key={index} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <h2 className="text-lg font-bold mb-2">Add Group</h2>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Group Name
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={newGroup}
            onChange={(e) => setNewGroup(e.target.value)}
          />
        </div>
        <button
          onClick={handleAddGroup}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add Group
        </button>
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2">Groups</h2>
        <ul className="list-disc pl-5">
          {groups.map((group, index) => (
            <li key={index} className="mb-1">
              <span className="font-semibold">Group:</span> {group.groupName}
              <ul className="list-disc pl-5">
                {group.members.map((member, idx) => (
                  <li key={idx} className="text-gray-700">{member}</li>
                ))}
              </ul>
              <button
                onClick={() => setSelectedGroup(index)}
                className="text-blue-500 hover:text-blue-700 underline mt-1"
              >
                Add Member
              </button>
            </li>
          ))}
        </ul>
      </div>

      {selectedGroup !== null && (
        <div className="mt-4">
          <h3 className="text-lg font-bold mb-2">Add Member to {groups[selectedGroup].groupName}</h3>
          <div className="mb-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Member Name
            </label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newMember}
              onChange={(e) => setNewMember(e.target.value)}
            />
          </div>
          <button
            onClick={handleAddMember}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Member
          </button>
        </div>
      )}

      <div className="mt-4">
        <h2 className="text-lg font-bold mb-2">Get Game Info</h2>
        <button
          onClick={() => handleGetGameInfo(selectedGameId)}
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Get Info for Selected Game
        </button>
      </div>
    </div>
  );
};

export default Games;
