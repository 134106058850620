import React from 'react'

export default function Dashboard() {
  return (
    <div className='p-4 sm:ml-64 dark:bg-gray-900 min-h-screen dark:text-white'>
        <div className='flex flex-row flex-none justify-between items-center'>
            <p className='text-xl font-bold text-gray-700 dark:text-white py-6'>Dashboard</p>
        </div>
        <div className='flex flex-1 justify-between items-center'>
            <div className='flex-1 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 p-4'>
                <div className='flex justify-center items-center row-span-2 bg-gray-50 dark:bg-gray-800 rounded-xl w-full h-64'>
                    <p className='self-center dark:text-white text-xl font-bold'>User Manager</p>
                </div>
                {/* <div className='flex justify-center items-center bg-gray-50 dark:bg-gray-800 rounded-xl w-full h-64'>
                    <p className='self-center dark:text-white text-xl font-bold'>2</p>
                </div>
                <div className='flex justify-center items-center bg-gray-50 dark:bg-gray-800 rounded-xl w-full h-64'>
                    <p className='self-center dark:text-white text-xl font-bold'>3</p>
                </div> */}
                {/* <div className='flex justify-center items-center bg-gray-50 dark:bg-gray-800 rounded-xl w-full h-64'>
                    <p className='self-center dark:text-white text-xl font-bold'>4</p>
                </div> */}
            </div>
        </div>
    </div>
  )
}
