import React, { useState, useEffect, useRef } from 'react';
import SideBar from './SideBar';
import LicenseManager from './LicenseManager';
import Dashboard from './Dashboard';
import Whiteboard from './Whiteboard';
import Games from './Games';
import ProductActivation from "./ProductActivation";
import { Store } from 'react-notifications-component';

function Console() {
    const [json, setJson] = useState();

    const [tag, setTag] = useState('dashboard');
    const [isModalOpen, setModalOpen] = useState(false);
    const [roleName, setRoleName] = useState();

    const modalRef = useRef(null);
    const datePickerRef = useRef(null);

    const fetchData = async (session) => {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/get_accounts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session: `${session}`,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('get_accounts: ', data.accounts)
            setJson(data.accounts)
        })
        .catch(error => console.error('Error fetching data:', error));
    };

    const handleDropdownChange = (event, userName) => {
        if (!json.length) return;

        // Find the user to update
        const userToUpdate = json.find(user => user.user_name === userName);
        if (!userToUpdate) return;

        // Update the user's role
        const updatedUser = { ...userToUpdate, role_name: event.target.value };

        updateData(updatedUser, userName);
    };

    const handleStartFrom = (newValue, userName) => {
        if (!json.length) return;

        // Find the user to update
        const userToUpdate = json.find(user => user.user_name === userName);
        if (!userToUpdate) return;

        // Update the user's start_from
        const updatedUser = { ...userToUpdate, start_from: newValue };

        updateData(updatedUser, userName);
    }

    const handleEndOn = (newValue, userName) => {
        if (!json.length) return;

        // Find the user to update
        const userToUpdate = json.find(user => user.user_name === userName);
        if (!userToUpdate) return;

        // Update the user's end_on
        const updatedUser = { ...userToUpdate, end_on: newValue }
        updateData(updatedUser, userName);

    }

    const updateData = async (updatedUser, userName) => {
        // Replace the old user data with the updated data
        const updatedData = json.map(user => 
            user.user_name === userName ? updatedUser : user
        );

        let session = window.localStorage.getItem('session');

        await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/change_role`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session: `${session}`,
                uuid: `${updatedData.uuid}`,
                role: `${updatedData.role}`,
            }),
        })
        .then(response => {
            if (!response.ok) {
              console.log("response: ", response.status);
            }
            return response.json();
        })
        .then((data) => {
            console.log('data: ', data);
        })
        .catch(error => console.error('Error updating data:', error));

        await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/modify_license_start_date`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session: `${session}`,
                uuid: `${updatedData.uuid}`,
                start_date: `${updatedData.start_date}`,
            }),
        })
        .then(response => {
            if (!response.ok) {
              console.log("response: ", response.status);
            }
            return response.json();
        })
        .then((data) => {
            console.log('data: ', data);
        })
        .catch(error => console.error('Error updating data:', error));

        await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/modify_license_end_date`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session: `${session}`,
                uuid: `${updatedData.uuid}`,
                end_date: `${updatedData.end_date}`,
            }),
        })
        .then(response => {
            if (!response.ok) {
              console.log("response: ", response.status);
            }
            return response.json();
        })
        .then((data) => {
            console.log('data: ', data);
        })
        .catch(error => console.error('Error updating data:', error));

        // alert(`Data updated`);
        Store.addNotification({
            title: "Updated!",
            message: `Data updated`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
        });
        fetchData();  // Fetch the updated data
    }

    useEffect(() => {
        const role = localStorage.getItem('role');
        setRoleName(role);
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            // Check if clicked element (or its parent elements) is DatePicker
            if (datePickerRef.current && datePickerRef.current.contains(event.target)) {
              return;
            }
            // Close modal here
          }
        }
    
        // Attach the click event handler
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Cleanup
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, datePickerRef]);

    useEffect(() => {
        let session = window.localStorage.getItem('session');
        fetchData(session);
    }, []);

  return (
    <div className='flex-1'>
        {isModalOpen && (
            <div className="fixed inset-0 bg-opacity-50 max-h-screen" onClick={() => setModalOpen(false)}></div>
        )}

        <SideBar setTag={setTag} roleName={roleName}/>

        {/* <ReactNotifications 
            className='absolute top-0 right-0 z-50 bg-blue-300 dark:bg-slate-800 m-2 rounded border-l-2 border-blue-600'
        /> */}

        {
            tag === 'inbox' 
            ? 
                <div className='p-4 sm:ml-64 dark:bg-gray-900 min-h-screen dark:text-white'>
                    <div className='text-4xl'>Inbox</div>
                </div>
            : 
            tag === 'users' 
            ? 
                <LicenseManager 
                    isModalOpen={isModalOpen} 
                    setModalOpen={setModalOpen}  
                    modalRef={modalRef} 
                    datePickerRef={datePickerRef} 
                    json={json} 
                    handleDropdownChange={handleDropdownChange}
                    handleStartFrom={handleStartFrom}
                    handleEndOn={handleEndOn}
                    fetchData={fetchData}
                    updateData={updateData}
                />
            :
            tag === 'whiteboard'
            ?
                <div className="flex flex-col h-[200px]">
                    <div className="flex-grow relative bg-gray-100 min-h-screen">
                        <div className="overflow-auto sm:ml-64 bg-gray-100 dark:bg-gray-900 dark:text-white">
                            <Whiteboard />
                        </div>
                    </div>
                </div>
            :
            tag === 'Product Activation'
            ?
                <ProductActivation />
            :
            tag === 'Games'
            ?
                <Games />
            :
            <Dashboard />
        }
    </div>
  );
}

export default Console;
