import React, { useEffect } from 'react';
import Section from './homeworkSection';
import Edit from "./edit";
import List from "./organization_list";
import OrganizationDetail from './organizationDetail';
import { useState } from 'react'; 
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';

function HomeworkSection() {

    return (
        <>
            <Routes>
                <Route path="/" element={<Section />} />
                <Route path="list" element={<List />} />
                <Route path="edit" element={<Edit />} />
                <Route path="organization/:id" element={<OrganizationDetail />} />
            </Routes>
        </>
    );
  
}

export default HomeworkSection;