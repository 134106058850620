import React, { useEffect, useState, useRef } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const DatePickerCom = ({ startDate, endDate, user_name, handleStartFrom, handleEndOn }) => {
  const [isDarkMode, setIsDarkMode] = useState('light');

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    const newColorScheme = e.matches ? 'dark' : 'light';
    newColorScheme !== isDarkMode && setIsDarkMode(newColorScheme);
  });

  const theme = createTheme({
    palette: {
      mode: isDarkMode,
    },
  });

  useEffect(() => {
    const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    isDark ? setIsDarkMode('dark') : setIsDarkMode('light');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker 
            label="start date picker" 
            defaultValue={dayjs(startDate)} 
            onChange={(newValue) => handleStartFrom(newValue , user_name)} 
          />
          <DatePicker 
            label="end date picker"
            defaultValue={dayjs(endDate)}
            onChange={(newValue) => handleEndOn(newValue, user_name)}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DatePickerCom;