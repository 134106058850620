import React, { useEffect, useRef, useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Store } from 'react-notifications-component';

export default function SettingModal( {isModalOpen, setModalOpen, modalRef, userNameOpen, datePickerRef, fetchData, json, updateData}) {
    const [ startValue , setStartValue ] = useState(dayjs())
    const [ endValue, setEndValue ] = useState(dayjs());
    const [ roleName, setRoleName ] = useState('');
    const [ userName, setUserName ] = useState('');
    const [ id, setId ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ organization, setOrganization ] = useState('');

    const [ isDarkMode, setIsDarkMode ] = useState('light');

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        const newColorScheme = e.matches ? 'dark' : 'light';
        newColorScheme !== isDarkMode && setIsDarkMode(newColorScheme);
    });

    const theme = createTheme({
        palette: {
        mode: isDarkMode,
        },
    });

    const handleSubmit = () => {
        if (!startValue && !endValue && !roleName) return;
        if (!json.length) return;
        
        if (userNameOpen === 'New User') {
            const newUser = {
                user_name: `${userName}`,
                role_name: `${roleName}`,
                start_from: `${startValue}`,
                end_on: `${endValue}`,
                id: `${id}`,
                password: `${password}`,
                confirm_password: `${confirmPassword}`,
                organization: `${organization}`
            };

            // const updatedData = [...json, newUser];

            let session = window.localStorage.getItem('session');

            console.log('newUser: ', newUser);
            console.log('session: ', session)

            fetch('http://ec2-3-142-201-204.us-east-2.compute.amazonaws.com/account/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session: `${session}`,
                    username: `${newUser.user_name}`,
                    id: `${newUser.id}`,
                    password: `${newUser.password}`,
                    confirm_password: `${newUser.confirm_password}`,
                    role: `${newUser.role_name}`,
                    organization: `${newUser.organization}`,
                    start_date: `${newUser.start_from}`,
                    end_date: `${newUser.end_on}`,
                }),
            })
            .then(response => {
                if (!response.ok) {
                  console.log("response: ", response.status);
                }
                return response.json();
            })
            .then((data) => {
                console.log('data: ', data);
                fetchData();
            })
            .catch(error => console.error('Error updating data:', error));
            
            Store.addNotification({
                title: "Updated!",
                message: `Data updated`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1500,
                    onScreen: true
                }
            });
        
        } else {
            const userToUpdate = {
                user_name: `${userNameOpen}`,
                role_name: `${roleName}`,
                start_from: `${startValue}`,
                end_on: `${endValue}`
            };

            updateData(userToUpdate, userNameOpen);

        }

    };

    useEffect(() => {
        if (!json.length) return;
        if (userNameOpen === 'New User') return;

        const user = json.find(user => user.user_name === userNameOpen);
        if (!user) return;
                
        setStartValue(dayjs(user.start_from));
        setEndValue(dayjs(user.end_on));
        setRoleName(user.role_name);
    }, [userNameOpen])

    useEffect(() => {
        const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        isDark ? setIsDarkMode('dark') : setIsDarkMode('light');
    }, []);

  return (
    <div 
        ref={modalRef} 
        className={`fixed z-50 top-0 right-0 h-full w-128 bg-white dark:bg-gray-800 dark:text-white overflow-auto transform transition-transform ease-in-out duration-300 ${
        isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
    >
        <div className="p-4">
            <h1 className="text-lg font-semibold mb-4">{userNameOpen === 'New User' ? 'Add New User' : `Edit User ${userNameOpen}`}</h1>
            {/* <p className="text-sm">
                This is the modal content. You can put whatever you want here, including form elements, text, images, etc.
            </p> */}
            <div className="flex flex-col space-y-4">
                <label className="text-sm">User Name</label>
                <input 
                    type="text" 
                    className="p-1 rounded-md dark:bg-gray-950 focus:outline-none"
                    defaultValue={userNameOpen === 'New User' ? '' : userNameOpen}
                    onChange={e => setUserName(e.target.value)}
                />
                {
                    (userNameOpen === 'New User') && (
                        <>
                            <label className="text-sm">ID</label>
                            <input 
                                type="text" 
                                className="p-1 rounded-md dark:bg-gray-950 focus:outline-none"
                                onChange={e => setId(e.target.value)}
                            />
                            <label className="text-sm">Organization</label>
                            <input 
                                type="text" 
                                className="p-1 rounded-md dark:bg-gray-950 focus:outline-none"
                                onChange={e => setOrganization(e.target.value)}
                            />
                        </>
                    )
                }
                <label className="text-sm">Password</label>
                <input 
                    type="text" 
                    className="p-1 rounded-md dark:bg-gray-950 focus:outline-none"
                    onChange={e => setPassword(e.target.value)}
                />
                <label className="text-sm">Confirm Password</label>
                <input 
                    type="text" 
                    className="p-1 rounded-md dark:bg-gray-950 focus:outline-none"
                    onChange={e => setConfirmPassword(e.target.value)}
                />
                <label className='text-sm'>Role</label>
                <select 
                    value={userNameOpen === 'New User' ? '' : roleName}
                    onChange={e => setRoleName(e.target.value)}
                    className='p-1 rounded-md dark:bg-gray-950 focus:outline-none'
                >
                    <option value="" disabled>
                        Select an option
                    </option>
                    <option value="1">Root Account</option>
                    <option value="2">IT Admin</option>
                    <option value="3">IT Device</option>
                </select>
                <label className='text-sm'>Date</label>
                <ThemeProvider theme={theme} ref={datePickerRef}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker 
                                label="start date picker" 
                                value={dayjs(startValue)} 
                                onChange={(newValue) => {
                                    setStartValue(newValue);
                                }}
                            />
                            <DatePicker 
                                label="end date picker"
                                value={dayjs(endValue)}
                                onChange={(newValue) => setEndValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </ThemeProvider>
            </div>
            <div className='flex flex-row space-x-5 mt-4 justify-end'>
                <button 
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4 mx-5"
                    onClick={() => {
                        handleSubmit();
                        setModalOpen(false);
                    }}
                >
                    Submit
                </button>
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded mt-4 mx-5"
                    onClick={() => setModalOpen(false)}
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
  )
}
